import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { appRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerModule } from './components/spinner/spinner.module';
import { NgxPrintModule } from 'ngx-print';
import { FormVerificacionModule } from './components/form-verificacion/form-verificacion.module';
import { FacturaService } from './services/facturas.service';
import { ServicioTurnosService } from './services/servicioturnos.service';
import { VisitasService } from './services/visitas.service';
import { HorariosService } from './services/horarios.service';
import { SolicitudService } from './services/solicitud.service';
import { SucursalesService } from './services/sucursales.service';
import { BanersService } from './services/baners.service';
import { EspecialidadesService } from './services/especialidades.service';
import { PersonaService } from './services/persona.service';
import { LeyProteccionDatosPersonalesService } from './services/leyprotecciondatospersonales.service';
import { CuentasService } from './services/cuentas.service';
import { PrestamosService } from './services/prestamos.service';
import { InversionesService } from './services/inversiones.service';
import { RouterModule } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MovimientoCuentasService } from './services/movimientocuentas.service';
import { HistorialSesionService } from './services/historialsesion.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, {
      // useHash: true
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SpinnerModule,
    NgxPrintModule,
    FormVerificacionModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    FacturaService,
    ServicioTurnosService,
    VisitasService,
    HorariosService,
    SolicitudService,
    SucursalesService,
    BanersService,
    EspecialidadesService,
    PersonaService,
    LeyProteccionDatosPersonalesService,
    CuentasService,
    PrestamosService,
    InversionesService,
    MovimientoCuentasService,
    HistorialSesionService,
    provideAnimationsAsync()
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
