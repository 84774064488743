
export const environment = {
  production: true,
  base_url: 'https://api.futurolamanense.fin.ec/api',
  base_url_api_go: 'https://sistemflm.futurolamanense.fin.ec:8088/api/',
  base_url_api_go_turnos: 'https://sistemflm.futurolamanense.fin.ec:8092/api/',
  base_url_api_credito_online: 'https://sistemflm.futurolamanense.fin.ec:8093/api/',
  urlApiSimulador: "https://sistemflm.futurolamanense.fin.ec:8095/api",
  googleAnalyticsId: 'G-Q4GDK1QNTQ',
};


/*
export const environment = {
  production: true,
  base_url: 'https://api.futurolamanense.fin.ec/api',
  base_url_api_go: 'https://sistemflm.futurolamanense.fin.ec:8088/api/',
  base_url_api_go_turnos: 'https://sistemflm.futurolamanense.fin.ec:8092/api/',
  base_url_api_credito_online: 'https://sistemflm.futurolamanense.fin.ec:8093/api/',
  urlApiSimulador: "https://sistemflm.futurolamanense.fin.ec:8095/api",
  googleAnalyticsId: 'G-Q4GDK1QNTQ',
};


base_url: 'http://localhost:8088/api',
*/
