import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: "",
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "login",
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "register",
    loadChildren: () => import("./pages/register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "registrarse",
    loadChildren: () => import("./pages/registrarse/registrarse.module").then((m) => m.RegistrarseModule),
  },
  {
    path: 'banca',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
];


/*
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(
  appRoutes,
  { relativeLinkResolution: 'legacy' }
);
*/