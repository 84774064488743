import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root',
})
export class InversionesService {

  constructor(private http: HttpClient) { }

  GetInversiones(): Observable<any> {
    const token = sessionStorage.getItem('token');
    return this.http.get(`${base_url}/inversiones/inversiones-por-numcliente`, {
      headers: { 'x-force': token },
    });
  }

}
