import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const base_url = environment.base_url;

@Injectable({
    providedIn: 'root',
})
export class MovimientoCuentasService {

    constructor(private http: HttpClient) { }

    getMovimientos(secuencialCuenta: number, fechainicio: string, fechafin: string): Observable<any> {
        let params = JSON.stringify(
            {
                secuencialcuenta: secuencialCuenta,
                fechainicio: fechainicio,
                fechafin: fechafin
            }
        );
        const token = sessionStorage.getItem('token');

        return this.http.post(`${base_url}/movimientocuentas/movimientoscuentasporfecha`, params, {
            headers: {
                'x-force': token,
                "Content-Type": "application/json",
            },

        });
    }

}
