import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const base_url = environment.base_url;

@Injectable({
    providedIn: 'root',
})
export class PrestamosService {

    constructor(private http: HttpClient) { }

    GetPrestamos(): Observable<any> {
        const token = sessionStorage.getItem('token');
        return this.http.get(`${base_url}/prestamo/prestamos`, {
            headers: { 'x-force': token },
        });
    }

}
